<template>
	<Component
		:is="wrapper"
		class="list-icons-icon margin-bottom-xl"
		v-bind="link"
	>
		<NuxtImg
			:alt="image.alt"
			:src="image.src"
			loading="lazy"
			class="margin-bottom-m img-wrapper"
		></NuxtImg>
		<div class="content">
			<TnParagraph
				v-if="icon.iconHeading"
				v-editable-string
				:dark="dark"
				bold
				class="margin-bottom-s"
				responsive
				size="xl"
				type="h3"
			>
				{{ icon.iconHeading }}
			</TnParagraph>
			<TnParagraph
				v-editable-string
				:dark="dark"
				class="paragraph"
				responsive
				size="l"
				>{{ icon.iconText }}
			</TnParagraph>
			<LinkInline
				v-if="link && !icon.hideReadMore"
				v-bind="link"
				:dark="dark"
				chevronRight
				class="read-more margin-top-m"
				size="m"
				text="Les mer"
			/>
		</div>
	</Component>
</template>

<script>
import LinkViewModel from "../../../../helpers/ViewModels/LinkViewModel.js";
import SupportsDarkMode from "../../../../mixins/SupportsDarkMode";

export default defineNuxtComponent({
	name: "ListIconsIcon",

	props: {
		icon: {
			type: Object,
			default: () => {},
		},
	},

	computed: {
		wrapper() {
			if (this.link && this.icon.hideReadMore) return "LinkWrap";
			return "div";
		},
	},

	mixins: [SupportsDarkMode],

	data() {
		return {
			link: this.icon.iconLink && LinkViewModel(this.icon.iconLink),
			image: this.icon.iconImage,
		};
	},
});
</script>

<style lang="scss" scoped>
.list-icons-icon {
	.img-wrapper {
		height: 100px;
	}

	.paragraph {
		color: $color-neutrals-600-shade;
	}

	:deep(img) {
		width: auto;
		height: 100%;
	}

	@include breakpoint(mobile) {
		display: flex;

		:deep() {
			.img-wrapper {
				width: 48px;
				height: 48px;
				margin-bottom: 0;
				flex-shrink: 0;
			}

			.content {
				padding-left: $spacing-l;
			}
		}
	}
}
</style>
